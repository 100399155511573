<template lang="pug">
.page
  el-card.filterCard
    el-form(inline label-width="6em")
      el-form-item(label="搜索")
        el-input(v-model="filter.search")
      el-form-item()
        el-button(type="primary" icon="el-icon-search" @click="handleSearch") 搜索
        el-button(type="warning" icon="el-icon-refresh" @click="initData") 重置
        el-button(type="success" icon="el-icon-plus" @click="handleAdd") 添加
  el-card.tableCard(v-loading="loading")
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="name" label="名称")
      el-table-column(prop="desc" label="备注")
      el-table-column(prop="category" label="类型")
      el-table-column(fixed="right" label="操作" width="300")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)") 编辑
          el-button(type="info" icon="el-icon-question" size="mini" plain @click="handleJumpQuestion(scope.row)") 题库
          el-button(type="info" icon="el-icon-document" size="mini" plain @click="handleJumpResult(scope.row)") 报告
          //- el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain) 删除
    .footer
      el-pagination(v-model:currentPage="pagination.page"
        v-model:pageSize="pagination.size"
        :pageSizes="[10, 20, 50, 100, 200]"
        :total="pagination.total"
        layout="sizes, prev, pager, next"
        background
        @size-change="handleSearch"
        @current-change="getList")

  el-dialog(v-model="isShowEditDialog")
    el-form(label-width="6em")
      el-form-item(label="id")
        el-input(v-model="editFormData._id" :disabled="isEditMode")
      el-form-item(label="名称")
        el-input(v-model="editFormData.name")
      el-form-item(label="备注")
        el-input(v-model="editFormData.desc")
      el-form-item
        el-button(type="primary" @click="handleSubmitEdit") 保存

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const { fetch, router, message } = inject('global')

const loading = ref(false)
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})
const filter = ref({
  search: ''
})

const isShowEditDialog = ref(false)
const isEditMode = ref(false)
const editFormData = ref({
  _id: ''
})

function handleSearch() {
  list.value = []
  pagination.value.page = 1
  getList()
}
function initData() {
  filter.value.search = ''
  handleSearch()
}
function getList() {
  loading.value = true
  let params = {
    ...pagination.value
  }
  if (filter.value.search) {
    params.search = filter.value.search
  }
  fetch
    .get('/evaluation', { params })
    .then(res => {
      list.value = res.list
      pagination.value.total = res.page.total
    })
    .finally(() => {
      loading.value = false
    })
}

function handleAdd() {
  isShowEditDialog.value = true
  isEditMode.value = false
}
function handleEdit(val) {
  editFormData.value = val
  isShowEditDialog.value = true
  isEditMode.value = true
}
function handleSubmitEdit() {
  fetch
    .put(`/evaluation`, {
      ...editFormData.value
    })
    .then(res => {
      message.success(res?.msg || '保存成功')
      isShowEditDialog.value = false
      initData()
    })
    .catch(err => {
      message.error(err?.msg || err || '系统异常')
    })
}
function handleJumpQuestion(val) {
  router.push(`/evaluation/${val._id}/question`)
}
function handleJumpResult(val) {
  router.push(`/evaluation/${val._id}/result`)
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.tableCard {
  .el-table {
    min-height: 600px;
  }
}
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
